<template>
    <div class="contacts pl-9 pr-9 header-clear">
        <div class="wrapper">
            <v-row class="pb-9 ma-0">
                <h2 class="mt-12">Контакти</h2>
            </v-row>
            <v-col>
                <iframe class="mb-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1465.0257929803602!2d23.27714491792918!3d42.744960796946984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa905bcb744faf%3A0xe8413619670b9905!2sP2P%20internet%20service%20provider!5e0!3m2!1sen!2suk!4v1581764657119!5m2!1sen!2suk" width="100%" height="500" frameborder="0" allowfullscreen=""></iframe>
            </v-col>
        </div>
    </div>
</template>
<style lang="css">
    .list {
        list-style: none;
    }
    .submission-form label {
        font-size: 0.875rem;
        color: #999;
    }
    .submission-form input,
    .submission-form textarea {
        border: 1px solid #ccc;
        border-radius: 4px;
        display: block;
        width: 100%;
        padding: 5px 10px;
        font-size: 1rem;
    }
    .submission-form input[type="submit"]:hover {
        color: #fff;
        background-color: #E66A1E;
    }
</style>
<script>
    export default {
        data () {
            return {
                telephones: [
                    { num: '02/ 934 24 27' },
                    { num: '0897 80 52 19' },
                    { num: '0888 72 40 19' },
                    { num: '0888 25 60 96' },
                    { num: '0878 02 25 69' },
                ],
            }
        },
    }
</script>